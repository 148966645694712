function Header() {
  return (
    <header>
      <video src="/video.mp4" loop autoPlay muted></video>
      <img src="towers-solution-logo.jpeg" alt="Towers Solution"></img>
      <div ba="headerbg"></div>
    </header>
  );
}
export default Header;
