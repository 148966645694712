import Card from "./Card";
function Services() {
  return (
    <div className="container services">
        <h2 className="main-title text-center">SERVICES</h2>
        <div className="card-cover">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mb-2">
                        <Card title="Web Development" img="card1.png" text="Expirence developing web aplications with Angular, React and simple vanilla." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Cloud architecture" img="card2.png" text="We have been working with clints using AWS, where we use infraestructure as code to define the whole architecture needed for a full solution up an running." />
                    </div>
                    <div className="col-md-4 mb-2">
                    <Card title="Consulting" img="card3.png" text="We provide the service of consulting, where we can make an audit of your current architecture and provide notes, and suggestions if is needed." />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
export default Services;
